import React from 'react'
import { Form } from "react-bootstrap"
import Col from "react-bootstrap/Col"
import Select from 'react-select'


const SelectField = ({name, required, placeholder, values, handlechange,  fieldClass, label, grpmd, componentprops}) => {
  var selectvalues = []
  {values.map(val =>selectvalues.push({ value:val.value, label: val.label } ))}
  return (
  
  <Form.Group  md={grpmd} controlId={`validation${name}`} className="select-option form-field-group dropdown">
    { label
      ? <Form.Label className="form-label">{label}{required ? '*' : ''}</Form.Label>
      : ''

    }
<div className="d-md-flex justify-content-between form-page-radio">

    {selectvalues.length > 0 && selectvalues.map((item, index) => (

<Form.Check
    type={"radio"}
    label={item.label}
    id={item.label}
    required={required}
    value={item.value}
    name={name}
    onChange={handlechange}
    className="form-radio"
/>
    ))}
</div>
    
    {/* <Form.Control
		className={fieldClass}
		required={required}
		name={name}
		onChange={handlechange}
		as="select"
    value={componentprops ? componentprops[name] : ''}
		>
		{values.map(val =><option value={val} key="">{val}</option>)}
    </Form.Control> */}
  </Form.Group>
  )
}

export default SelectField;