import React from "react"

const AreaList = ({ areas, removeArea, className = "" }) => {
  if (!areas || areas.length < 1) return <></>

  function removeAreaFun(e, area) {
    e.preventDefault()
    removeArea(area)
  }
  function removeAreaAllFun(e, area) {
    e.preventDefault()
    removeArea("", true)
  }

  return (
    <>

          <ul className="selected-filters-container">
            {areas.map((item, key) => {
              return (
                <>
                  <li className="filter-item">
                    <p className="filter-text">{item.name}</p>

                    <i
                      className="icon icon-close"
                      onClick={e => removeAreaFun(e, item.slug)}
                    ></i>
                    {/* <div className="tag">
                      <a
                        href="javascript:;"
                        className="tag-clear link-tag-clear pointer-events-all cursor-pointer"
                        onClick={e => removeAreaFun(e, item.slug)}
                      ></a>
                    </div> */}
                  </li>
                </>
              )
            })}
            <li className="filter-item clear-items">
              <button
                className="clear-btn"
                onClick={e => removeAreaAllFun(e, "")}
              >
                Clear All
              </button>
            </li>
            {/* <li className="pointer-events-all clear-btn-area">
              <div className="tag">
                <div
                  className="tag-text"
                  onClick={e => removeAreaAllFun(e, "")}
                >
                  <span>Clear All</span>
                </div>
                <a
                  className="tag-clear link-clear-all m-0"
                  onClick={e => removeAreaAllFun(e, "")}
                ></a>
              </div>
            </li> */}
          </ul>
    </>
  )
}

export default AreaList
