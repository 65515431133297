import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import $ from "jquery"
import { GetPageURL, phoneNumber } from "../common/site/utils"
import EnquireModal from "../EnquireModal/EnquireModal"

const Menudata = props => {
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        burgerMenus(sort: "sort:asc") {
          label
          custom_link
          sort
          menu {
            slug
            id
            parent {
              slug
              parent {
                slug
              }
            }
          }
          add_menu {
            label
            custom_link
            menu {
              id
              slug
              parent {
                slug
                parent {
                  slug
                }
              }
            }
          }
        }
      }
    }
  `)

  const allmenus = data.glstrapi.burgerMenus

  const [modalShow, setModalShow] = useState(false)

  useEffect(() => {
    $(".navigation li a").click(function () {
      $(window).scrollTop(0)
    })
    $(".navigation .menu-accordian .submenu-list").each(function (i, el) {
      // Remove white space
      var html = $(el).html().replace(/\s+/, "")
      // Check if element is :empty or length of html is zero
      if ($(el).is(":empty") || html.length === 0) {
        $(el).remove()
      } else {
        $(el).parent().addClass("has-submenu")
      }
    })

    $(".card-header.has-submenu .firstleveltrigger")
      .unbind()
      .on("click", function (e) {
        var checkactive = $(this).parent().attr("class")
        if (checkactive.indexOf("active") !== -1) {
          $(".card-header.has-submenu").removeClass("active")
          $(".submenu-list").stop(true, true).slideUp()
          $(".has-submenu .firstleveltrigger i").removeClass("icon-minus")
          $(".has-submenu .firstleveltrigger i").addClass("icon-plus")
        } else {
          $(".card-header.has-submenu").removeClass("active")
          $(".submenu-list").slideUp()
          $(".has-submenu .firstleveltrigger i").removeClass("icon-minus")
          $(".has-submenu .firstleveltrigger i").addClass("icon-plus")
          $(this).parent().addClass("active")
          $(this).parent().find(".submenu-list").stop(true, true).slideDown()
          $(this).parent().find(".firstleveltrigger i").removeClass("icon-plus")
          $(this).parent().find(".firstleveltrigger i").addClass("icon-minus")
        }
      })
  }, [])

  // var IfExternal_URL = /^((http|https|ftp):\/\/)/;

  return (
    <React.Fragment>
      <div className="navigation">
        <div className="menu-accordian">
          {allmenus &&
            allmenus.map((item, index) => {
              return (
                <li className={"card-header card-header-parent"}>
                  <Link
                    className="parent-link"
                    to={GetPageURL(item.menu, item.custom_link)}
                    onClick={() => props.handleClose()}
                  >
                    {item.label}
                  </Link>
                  <div className="firstleveltrigger">
                    {item.add_menu && item.add_menu.length > 0 && (
                      <i className="icon-plus"></i>
                    )}
                  </div>
                  <ul className="submenu-list">
                    {item.add_menu &&
                      item.add_menu.length > 0 &&
                      item.add_menu.map(submenu => {
                        return (
                          <li className="card-header card-header-child secondlevel-menu-item">
                            <Link
                              to={GetPageURL(submenu.menu, submenu.custom_link)}
                              onClick={() => props.handleClose()}
                            >
                              {submenu.label}
                            </Link>
                          </li>
                        )
                      })}
                  </ul>
                </li>
              )
            })}
          <div className="nav-actions d-flex align-items-center">
            <a className="btn btn-black" href="/property-valuation-in-dubai/">
              {" "}
              list your property{" "}
            </a>
            <button
              className="btn btn-white"
              onClick={() => setModalShow(true)}
            >
              enquire
            </button>
          </div>
          <div className="burger-ct d-flex align-items-center">
            <i className="icon-phone"></i>Prefer a call instead?{" "}
            <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
          </div>
        </div>
      </div>
      <EnquireModal show={modalShow} onHide={() => setModalShow(false)} />
    </React.Fragment>
  )
}

export default Menudata
