import React from 'react'
import { Form, Button, Dropdown } from "react-bootstrap";
import Col from "react-bootstrap/Col"
import { Link } from "gatsby"

const ButtonField = ({type, name, value, fieldClass, step,handlechange,handlechange2}) => (
	<>
		<Button variant="" type={type}  className={`${fieldClass ? fieldClass : "btn btn-black"}`}>
			{name}
			<i className="icon-right"></i>
		</Button>
		<div className="terms">
              <p>
                By clicking Submit you agree to our{" "}
                <Link to="/terms-and-conditions">Terms</Link> and{" "}
                <Link to="/privacy-policy">Privacy Policy</Link>.
              </p>
              <p className="required">* required field</p>
		</div>
	</>
);

export default ButtonField;