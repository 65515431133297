import React from "react"
import Modal from "react-bootstrap/Modal"
import "./MoreFiltersModal.scss"

const MoreFiltersModal = props => {
  const amenitiesFilters = [
    { name: "parking", label: "Parking" },
    { name: "Water-View", label: "Water View" },
    { name: "Private-Garden", label: "Private Garden" },
    { name: "Vacant-on-Transfer", label: "Vacant on Transfer" },
    { name: "View-of-a-Landmark", label: "View of a Landmark" },
    { name: "Private-Pool", label: "Private Pool" },
    { name: "Communal-Gardens", label: "Communal Gardens" },
    { name: "Upgraded-and-Furnished", label: "Upgraded and Furnished" },
  ]
  return (
    <div className="more-filters-modal-wrapper">
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="more-filters-modal"
        id="filter-modal"
        fullscreen={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h3 className="title">More Filters</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body-container">
            <div className="amenities-container">
              <p className="filter-title">Amenities</p>
              <form className="filters">
                {amenitiesFilters.map(filter => (
                  <div className="checkbox-box">
                    <input
                      type="checkbox"
                      id={filter.name}
                      name={filter.name}
                      value={filter.name}
                    />
                    <label for={filter.name}>{filter.label}</label>
                  </div>
                ))}
              </form>
              <button className="update-btn btn btn-black">
                update results
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default MoreFiltersModal
