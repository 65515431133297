import { useState, useEffect } from "react"

// hook to check if user has scrolled from top
// returns truthy value if scrolled
const useHasScrolled = () => {
  const [hasScrolled, setHasScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        setHasScrolled(true)
      } else {
        setHasScrolled(false)
      }
    }

    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  return hasScrolled
}

export default useHasScrolled
